import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from 'swiper/react'

import Layout from '../components/layouts'

export default function TechnicalDevelopment(props) {
  const images = props.data.allContentfulIdeations.edges[0].node.images

  return (
    <Layout>
      <div className="gallery">
        <div style={{ width: '90vw', maxWidth: '1024px', margin: 'auto' }}>
          <Swiper slidesPerView={1} centeredSlides loop navigation>
            {images.map((image) => (
              <SwiperSlide key={image.id}>
                <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query technicalDevelopmentQuery {
    allContentfulIdeations(filter: { title: { eq: "Technical Development" } }) {
      edges {
        node {
          images {
            id
            title
            gatsbyImageData(quality: 80, placeholder: NONE)
          }
        }
      }
    }
  }
`
